<template>

  <div class="primary-section-content pb-2">

    <template>
      <hb-modal
          v-model="isModalVisible"
          title="Change Summary"
          @close="closeModal"
          class="custom-modal">

        <template v-slot:title>
          <div v-if="modalData.userDateTime">
            <HbIcon mdi-code="mdi-account-clock-outline" smallcolor="#XXXXXX" />
            {{ modalData.userDateTime }}
          </div>
        </template>
        <!-- TODO This is temporary. Move the template -->
        <template v-slot:content>
          <div class="hb-modal-content">
            <div class="entity-title"> <!-- Add padding with margin-bottom -->
              <strong>{{ modalData.entityText }}</strong>
            </div>
            <div v-if="!modalData.modified_data || Object.keys(modalData.modified_data).length === 0" class="entity-title">
              <p>No changes detected</p>
            </div>
            <table v-else class="hb-modal-table">
              <thead>
              <tr>
                <th class="font-weight-medium">Token</th>
                <th class="font-weight-medium">Changed To</th>
                <th class="font-weight-medium">Changed From</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(value, key) in modalData.modified_data" :key="key">
                <!-- Token Column -->
                <td class="font-weight-medium">{{ key }}</td>

                <!-- Changed To Column -->
                <td>
                  <!-- For simple values, display only the "new" value if there is no added/removed property -->
                  <div v-if="isSimpleValue(value) && !hasAddedOrRemoved(value)">
                    {{ value.new }}
                  </div>

                  <!-- For Regular JSON Array (e.g., simple strings or numbers) -->
                  <div v-if="isJsonArray(value)">
                    <!-- For the "Added" section -->
                    <div v-if="value.added && value.added.length > 0">
                      <span class="font-weight-medium">Added:</span>
                      <ul>
                        <li v-for="(item, index) in value.added" :key="index">
                          <!-- Check if the item is a primitive (string or number), and display it -->
                          <span v-if="typeof item === 'string' || typeof item === 'number'">{{ item }}</span>

                          <!-- If it's an object, loop through its properties to display key-value pairs -->
                          <div v-else>
                            <ul>
                              <li v-for="(val, subKey) in item" :key="subKey">
                                {{ subKey }}: {{ val }}
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <!-- For the "Removed" section -->
                    <div v-if="value.removed && value.removed.length > 0">
                      <span class="font-weight-medium">Removed:</span>
                      <ul>
                        <li v-for="(item, index) in value.removed" :key="index">
                          <!-- Display primitives directly -->
                          <span v-if="typeof item === 'string' || typeof item === 'number'">{{ item }}</span>

                          <!-- If it's an object, loop through its properties to display key-value pairs -->
                          <div v-else>
                            <ul>
                              <li v-for="(val, subKey) in item" :key="subKey">
                                {{ subKey }}: {{ val }}
                              </li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>

                </td>

                <!-- Changed From Column -->
                <td>
                  <!-- For simple values, display only the "old" value in Changed From -->
                  <div v-if="isSimpleValue(value) && !hasAddedOrRemoved(value)">
                    {{ value.old }}
                  </div>

                  <!-- For JSON arrays, leave the Changed From section empty -->
                  <div v-if="isJsonArray(value)">
                    <!-- Empty for JSON arrays -->
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </template>

        <template v-slot:right-actions>
          <hb-btn color="secondary" @click="closeModal">Close</hb-btn>
        </template>
      </hb-modal>
    </template>

    <hb-header full>
      <hb-page-header
          title="Audit Trail"
          description="Audit trail of all the configuration settings changes and user actions on Hummingbird."
      ></hb-page-header>
    </hb-header>

    <div class="audits-content-section-wrapper mt-5">

      <v-row no-gutters>

        <div class="audit-filters">
          <HbSelect
              box
              placeholder="Filter by Category"
              v-model="filters.category"
              :items="uniqueValues('category')"
              class="pr-3 pb-3"
          />
        </div>

        <div class="audit-filters">
          <HbSelect
              box
              placeholder="Filter by Entity"
              v-model="filters.entity"
              :items="uniqueValues('entity')"
              class="pr-3 pb-3"
          />
        </div>

        <div class="audit-filters">
          <HbSelect
              box
              placeholder="Filter by Action"
              v-model="filters.action"
              :items="uniqueValues('action')"
              class="pr-3 pb-3"
          />
        </div>

        <hb-btn @click="resetFilters" color="secondary" class="pr-3 pb-3">Reset Filters</hb-btn>

        <div class="audit-filters">
          <HbSelect
              box
              placeholder="Select Field"
              v-model="filters.selectedField"
              :items="fieldOptions"
              class="pr-3 pb-3"
          />
        </div>

        <div class="audit-filters">
          <HbTextField
              box
              placeholder="Search"
              v-model="filters.searchText"
              class="pr-3 pb-3"
              @keydown.enter="triggerSearch"
          />
        </div>

        <!-- Button to Trigger Search -->
        <hb-btn @click="triggerSearch" color="secondary" class="pr-3 pb-3">Search</hb-btn>

<!--        <div class="audit-filters">
          <HbSelect
              box
              placeholder="Query"
              v-model="filters.selectedQueryType"
              :items="queryTypeOptions"
              class="pr-3 pb-3"
          />
        </div>-->

      </v-row>

      <div>
        <hb-data-table
            :headers="headers"
            :items="formattedAudits"
            :loading="loadingAudits"
            loading-text="Loading Audits..."
            class="mt-1 mb-3"
            @click:row="openModal">

          <template v-slot:item.timestamp="{ item }">
            {{ item.timestamp | formatTime }}
          </template>

          <template v-slot:item.entityObject="{ item }">
            {{ item.entityObject }}
          </template>

          <template v-slot:item.entityObject="{ item }">
            {{ item.entityObject }}
          </template>

<!--          <template v-slot:item.modified_data="{ item }">
            <ul v-if="item.action !== 'Delete' && item.action !== 'Create'" class="pl-3" style="list-style-type: none;">
              <li v-for="(value, key) in item.modified_data" :key="key" style="margin-bottom: 5px;">
                <strong>{{ key }}:</strong>
                <ul style="list-style-type: none; margin-left: 15px;">
                  <li v-for="(subValue, subKey) in value" :key="subKey">
                    <strong>{{ formatSubKey(subKey) }}: </strong>
                    <span style="color: #555555;">{{ subValue }}</span>
                  </li>
                </ul>
              </li>
            </ul>
          </template>

          <template v-slot:item.modified_data="{ item }">
            <a href="#" @click.stop.prevent="openModal(item)">View Details</a>
          </template>-->

          <template v-slot:item.contact="{ item }">
            {{ item.contact_email }}
          </template>

          <template v-slot:item.user_agent="{ item }">
              {{ trimUserAgent(item.user_agent)}}
          </template>

        </hb-data-table>

      </div>

    </div>

  </div>
</template>

<script type="text/babel">
import { mapGetters, mapActions } from 'vuex';
import api from '../../assets/api.js';
import moment from 'moment';
import { notificationMixin } from  '../../mixins/notificationMixin.js';
export default {
  name: "Audits",
  mixins: [notificationMixin],
  modalData: {
    type: Object,
    required: true
  },
  data() {
    return {
      filters: {
        action: '',
        entity: '',
        category: '',
        selectedField: '',
        searchText: '',
        selectedQueryType: 'elastic-search'
      },
      isModalVisible: false,
      modalData: null,
      fieldOptions: [
        {text: 'Change Summary', value: 'modified_data'},
        {text: 'Entity', value: 'entity'},
        {text: 'User', value: 'user'},
        {text: 'Email', value: 'contact_email'},
        {text: 'Initial Record', value: 'is_base_record'},
      ],
      queryTypeOptions: [
        {text: 'Elastic Search', value: 'elastic-search'},
        {text: 'MySQL', value: 'mysql'},
        {text: 'MongoDB', value: 'mongodb'},
      ],
      audits: [],
      filteredAudits: [],
      loadingAudits: false,
      headers: [
        /*{text: 'Id', value: '_id', width: '160px'},*/
        {text: 'Timestamp', value: 'timestamp', width: '200px'},
        {text: 'Category', value: 'category', width: '150px'},
        {text: 'Entity', value: 'entity', width: '200px'},
        {text: 'Level', value: 'level', width: '150px'},
        {text: 'Sub Entity', value: 'sub_entity', width: '140px'},
        /*{ text: 'Entity Id', value: 'entity_id', width: '100px' },
        { text: 'Action', value: 'action', width: '100px' },
        { text: 'Entity Name', value: 'entity_name', width: '150px' },*/
        {text: 'Entity Object', value: 'entityObject', width: '300px'},
        /*{text: 'Change Summary', value: 'modified_data', width: '300px'},*/
        {text: 'User', value: 'user', align: 'center', width: '200px'},
        {text: 'Email', value: 'contact', align: 'center', width: '170px'},
        {text: 'Base Record', value: 'is_base_record', align: 'center', width: '50px'},
        {text: 'IP Address', value: 'ip_address', width: '90px'},
        {text: 'User Agent', value: 'user_agent', width: '320px'},
      ],
    }
  },
  mounted() {
    this.fetchAuditData();
  },
  filters: {
    formatTime: function (value) {
      if (!value) return '';
      return moment.utc(value).local().format('MMM D, YYYY [ @ ] h:mma');
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'authenticationStore/isAdmin',
      properties: 'propertiesStore/filtered',
      getUserData: 'authenticationStore/getUserData',
      hasPermission: 'authenticationStore/rolePermission'
    }),
    formattedAudits() {
      const dataToFormat = this.filters.action || this.filters.entity || this.filters.category
          ? this.filteredAudits
          : this.audits;

      return dataToFormat.map(audit => {
        let entityObject = ''; //this.getEntityText(audit.action);
        switch (audit.action) {
          case 'Create':
            entityObject = `Added: ${audit.entity_name}`;
            break;
          case 'Update':
            entityObject = `Modified: ${audit.entity_name}`;
            break;
          case 'Delete':
            entityObject = `Deleted: ${audit.entity_name}`;
            break;
          case 'Payment':
            entityObject = `Payment processed: ${audit.entity_name}`;
            break;
          default:
            entityObject = `${audit.action}: ${audit.entity_name}`; // fallback
        }
        return {
          ...audit,
          entityObject, // Add the computed entityObject to the audit object
        };
      });
    }
  },
  methods: {
    async fetchAuditData() {
      this.loadingAudits = true;
      try {
        let response = await api.get(this, api.AUDITING);
        console.log('Response:', JSON.stringify(response));
        this.audits = response.auditResults; // Use dynamic API data later
      } catch (error) {
        console.error('Error fetching audit logs:', error);
      } finally {
        if (this.filters.action || this.filters.entity || this.filters.category) {
          this.runFilters();
        }
        this.loadingAudits = false;
      }
    },
    async triggerSearch() {
      this.loadingAudits = true;
      try {
        const params = {
          category: this.filters.category,
          entity: this.filters.entity,
          action: this.filters.action,
          field: this.filters.selectedField,
          search: this.filters.searchText,
          type: this.filters.selectedQueryType,
        };

        let queryString = `?search=${params.search}&entity=${params.entity}&action=${params.action}&category=${params.category}&type=${params.type}`;
        if (params.field) {
          queryString += `&field=${params.field}`;
        }
        let response = await api.get(this, api.AUDITING + queryString);
        this.audits = response.auditResults;
      } catch (err) {
        console.error('Error searching audit logs:', err);
      } finally {
        if (this.filters.action || this.filters.entity || this.filters.category) {
          this.runFilters();
        }
        this.loadingAudits = false;
      }
    },
    uniqueValues(field) {
      if (!this.audits || this.audits.length === 0) {
        return []; // Return an empty array if audits is undefined or empty
      }
      return [...new Set(this.audits.map(audit => audit[field]))];
    },
    resetFilters() {
      this.filters.action = '';
      this.filters.entity = '';
      this.filters.category = '';
      this.filters.selectedField = '';
      this.filters.searchText = '';
      this.fetchAuditData();
    },
    runFilters() {
      this.filteredAudits = this.audits;

      if (this.filters.action) {
        this.filteredAudits = this.filteredAudits.filter(i => i.action === this.filters.action);
      }

      if (this.filters.entity) {
        this.filteredAudits = this.filteredAudits.filter(i => i.entity === this.filters.entity);
      }

      if (this.filters.category) {
        this.filteredAudits = this.filteredAudits.filter(i => i.category === this.filters.category);
      }
    },
    formatSubKey(key) {
      return key.charAt(0).toUpperCase() + key.slice(1); // Customize key formatting
    },
    hasAddedOrRemoved(value) {
      return (value.added && Array.isArray(value.added)) || (value.removed && Array.isArray(value.removed));
    },
    isSimpleValue(value) {
      // Check if the value is a simple value (not an array or object)
      return typeof value === 'object' && !Array.isArray(value);
    },
    isJsonArray(value) {
      // Check if the value contains an array with added or removed values
      return value.added !== undefined || value.removed !== undefined;
    },
    openModal(item) {
      console.log('Row clicked:', item);
      this.modalData = {
        modified_data: item.modified_data || {},
        entityText: `${item.entity} > ${this.getEntityText(item)}`,
        userDateTime: this.getUserDateTime(item)
      };

      this.isModalVisible = true;
    },

    closeModal() {
      this.isModalVisible = false;
      this.modalData = {
        modified_data: {},
        entityText: ''
      };
    },
    getEntityText(item) {
      switch (item.action) {
        case 'Create':
          return `Added: ${item.entity_name}`;
        case 'Update':
          return `Modified: ${item.entity_name}`;
        case 'Delete':
          return `Deleted: ${item.entity_name}`;
        case 'Payment':
          return `Payment processed: ${item.entity_name}`;
        default:
          return `${item.action || 'Unknown'}: ${item.entity_name || 'Entity'}`; // Fallback for undefined action/entity_name
      }
    },
    getUserDateTime(item) {
      const formattedTime = this.$options.filters.formatTime(item.timestamp);
      return `${item.user}, ${formattedTime}`;
    },
    trimUserAgent(userAgent) {
      const osMatch = userAgent.match(/\((.*?)\)/); // Extracts everything between parentheses (OS)
      const browserMatch = userAgent.match(/(Chrome\/[\d\.]+|Firefox\/[\d\.]+|Safari\/[\d\.]+|Edge\/[\d\.]+)/); // Add more browsers as needed
      if (osMatch && browserMatch) {
        const os = osMatch[1]; // Get the OS information
        const browser = browserMatch[1]; // Get the browser version
        return `${browser} on ${os}`;
      }
      return userAgent;
    }

  },
  watch: {
    filters: {
      handler: function () {
        this.runFilters();
      },
      deep: true
    },
  }
}



</script>

<style scoped>

.audits-content-section-wrapper {
  padding-right: 20px;
}

.audit-filters {
  width: 210px;
}

/* Scoped CSS to limit table row height */
::v-deep(.hb-data-table__row) {
  max-height: 100px; /* Set your desired max height */
  overflow: hidden; /* Hide content that overflows */
  text-overflow: ellipsis; /* Add ellipsis for truncated text */
  white-space: nowrap; /* Prevent text wrapping */
}

.hb-modal-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.hb-modal-table th,
.hb-modal-table td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.hb-modal-table th {
  background-color: #f4f4f4;
}

.hb-modal-table td {
  vertical-align: top;
}

.hb-modal-table ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.hb-modal-table li {
  margin-bottom: 5px;
}

.hb-modal-table strong {
  font-weight: bold;
}

.entity-title{
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 10px;
}

.clickable-row {
  cursor: pointer;
}
.clickable-row:hover {
  background-color: #f5f5f5; /* Light gray background on hover */
}

</style>